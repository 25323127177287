.range-slider {
  display: block;
  position: relative;
  height: 2rem;
  width: 100%;
  user-select: none;
}

.range-slider-left,
.range-slider-right {
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 1rem;
  height: 1.3rem;
  width: 1.3rem;
  z-index: 2;
  cursor: grab;

  &::before {
    display: block;
    position: absolute;
    width: 2rem;
    height: 2rem;
    margin: -0.4rem 0 0 -0.4rem;
    content: "";
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
    background: $body-tertiary-bg;
    border: $input-border-width solid $input-group-addon-border-color;
    border-radius: 50%;
    outline: 2px solid $white;
  }
}

.range-slider-right {
  transform: translate(50%, -50%);
}

.range-slider-line {
  position: absolute;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  right: 0;
  top: 1rem;
  height: 4px;
  border-radius: 4px;
  display: flex;
  background: $body-tertiary-bg;
  z-index: 0;
  overflow: hidden;

  span {
    display: block;
    height: 100%;
    width: 100%;
    background: $primary;
  }
}

.range-slider-chart {
  display: flex;
  width: 100%;
  height: 3rem;
  margin: 1rem 0 -1rem;

  --range-slider-chart-bg-opacity: .4;
  --range-slider-chart-bg: #{$primary};
  --range-slider-chart-bg-active-opacity: .8;
  --range-slider-chart-bg-active: #{$primary};

  > div {
    height: 0;
    max-height: 100%;
    background: var(--range-slider-chart-bg);
    opacity: var(--range-slider-chart-bg-opacity);
    border-radius: 2px 2px 0 0;
    margin: auto 1px 0;
    transition: opacity 0.225s ease-in-out;

    &.active {
      background-color: var(--range-slider-chart-bg-active);
      opacity: var(--range-slider-chart-bg-active-opacity);
    }
  }
}
