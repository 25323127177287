html #cookies-widget {
  &.cookies-widget--dark {
    --bookingsync-cookieswidget-bg: #{mix($black, $cookies-widget-accent-color, 50%)};
    --bookingsync-cookieswidget-overlay-bg: #{rgba($black, .25)};
    --bookingsync-cookieswidget-color-rgb: 255, 255, 255;
    --bookingsync-cookieswidget-accent-primary: #{$white};
    --bookingsync-cookieswidget-accent-primary-alt: #{$white};
    --bookingsync-cookieswidget-accent-primary-contrast: #{$cookies-widget-accent-color};
    --bookingsync-cookieswidget-accent-primary-contrast-alt: #{$cookies-widget-accent-color};
    --bookingsync-cookieswidget-accent-muted: #{$text-muted};
  }

  &.cookies-widget--light {
    --bookingsync-cookieswidget-bg: #{$white};
    --bookingsync-cookieswidget-overlay-bg: #{rgba($black, .25)};
    --bookingsync-cookieswidget-color-rgb: 49, 73, 99;
    --bookingsync-cookieswidget-accent-primary: #{$cookies-widget-accent-color};
    --bookingsync-cookieswidget-accent-primary-alt: #{mix($black, $cookies-widget-accent-color, 10%)};
    --bookingsync-cookieswidget-accent-primary-contrast: #{$white};
    --bookingsync-cookieswidget-accent-primary-contrast-alt: #{$white};
    --bookingsync-cookieswidget-accent-muted: #{$text-muted};
  }

  input[type="checkbox"] {
    & + label::before {
      margin-right: 1em;
    }
  }
}
