nav-bar {
  transition: $transition-base;
  // z-index: $zindex-fixed;

  &:not(.navbar-expand) {
    .navbar-brand {
      order: 1;
      margin: 0 auto;
    }

    .navbar-toggler {
      position: absolute;
    }
  }

  .dropdown-menu {
    transition: $transition-cubic-short;
    transform: perspective(20rem) rotateY(30deg) translateY(-2rem) scale(0.7);
    pointer-events: none;
    z-index: $zindex-fixed + 1;

    &.show {
      pointer-events: all;
      transform: rotate(0deg) scale(1);
    }
  }
}
