$background: $dark;
$hover-background: $white;
$active-background: $red-400;

rentals-wishlist-add {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: $transition-base;

  background-color: $background;
  border-color: $background;
  color: color-contrast($background);

  &:hover {
    background-color: $hover-background;
    border-color: $hover-background;
    color: color-contrast($hover-background);
  }

  &.active {
    background-color: $active-background;
    border-color: $active-background;
    color: color-contrast($active-background); 
  }
}
