@keyframes incrementAnimation {
  0% {
    opacity: 0;
    transform: scale(120%);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: scale(90%);
  }
  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

@keyframes growOnce {
  0% {
    transform: scale(120%);
  }
  100% {
    transform: scale(100%);
  }
}

rentals-form {
  &[data-display=lg] {
    position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: $zindex-fixed;

    @include media-breakpoint-down(lg) {
      // background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 100%);
      // background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 100%);
      // order: 5;
      // top: unset;
      bottom: 0;
      padding: 1rem;
    }

    .rentals-form-group {
      width: 100%;
      box-shadow: $box-shadow-sm;
      
      @include media-breakpoint-down(lg) {
        border-radius: var(--bs-border-radius-pill);
        box-shadow: $box-shadow-lg;
      }

      @include media-breakpoint-up(lg) {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
      }
    }

    @include media-breakpoint-up(md) {
      .rentals-form-group {
        height: calc(#{$input-height} + (#{$spacer} * 2));
      }

      .dropdown-menu {
        padding-top: 1rem;
      }

      .dropdown-header {
        display: none !important;
      }

      .dropdown-footer {
        [name="next"],
        [type="submit"] {
          display: none;
        }
      }
    }
  }

  &[data-display=md] {
    @include media-breakpoint-up(lg) {
      .dropdown-header,
      .dropdown-divider,
      .dropdown-footer {
        display: none !important;
      }
    }

    .dropdown-menu {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    rentals-form-input {
      flex: 1 1 calc(100% / 3) !important;

      > .btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
      }
    }
  }

  &:not([data-display=sm]) {
    @include media-breakpoint-down(lg) {
      .dropdown-toggle {
        display: none !important;
      }
    }
  }

  .placeholder {
    min-width: 16ch;

    > rentals-form-option {
      display: none;
    }
  }

  .filter-options-header {
    padding: $dropdown-item-padding-y * 1.5 $dropdown-item-padding-x;

    h6 {
      padding: 0 0.25rem;
      margin-bottom: 0;
      color: var(--bs-dropdown-header-color);
    }
  }

  .filter-options-toggle-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: $dropdown-item-padding-y * 1.5 $dropdown-item-padding-x;

    > button {
      color: $dropdown-link-color;
      padding: 0 0.25rem;
      border: 0;
      border-radius: $border-radius-sm;
      background: none;
      transition: $transition-base;

      &::after {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: '';
      }

      &:hover {
        color: color-contrast($primary);
        background-color: $primary;
      }
    }
  }

  .filter-options-wrapper {
    display: none;

    &.show {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  .offcanvas {
    --bs-offcanvas-width: 100%;
    
    @include media-breakpoint-up(lg) {
      --bs-offcanvas-width: 45%;
    }
  }
}

rentals-form-input {
  position: relative;

  &[type="dates"],
  &[type="price-range"],
  &[type="increment"] {
    .dropdown-content {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &[type="select"] {
    [data-content] {
      display: flex !important;
      overflow: auto !important;
      scroll-snap-type: x mandatory !important;
      -ms-overflow-style: none;
      -webkit-scrollbar-width: none;
      -moz-scrollbar-width: none;
      -ms-scrollbar-width: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        display: none;
      }
    }

    rentals-form-option {
      scroll-snap-align: start !important;
      white-space: nowrap;
    }
  }

  .increment-value {
    min-width: 2ch;
    text-align: center;

    span {
      animation: incrementAnimation 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190) forwards;
    }
  }

  [name="minus"], [name="plus"] {
    &:active {
      animation: growOnce 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190) forwards;
    }
  }

  .dropdown-toggle::after {
    margin-left: 0.6em;
  }

  .dropdown-footer {
    display: flex;
    justify-content: space-between;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;

    .btn-link {
      margin-left: - $dropdown-item-padding-x * .5;
      padding-left: $dropdown-item-padding-x * .5;
      padding-right: $dropdown-item-padding-x * .5;
      box-shadow: none;
    }
  }

  .dropdown-menu {
    // disabled double tap zoom on IOS
    touch-action: manipulation;
    min-width: 13rem;
    max-width: calc(100vw - #{$grid-gutter-width});

    @include media-breakpoint-between(md, lg) {
      &.show {
        width: 60% !important;
        left: 20% !important;
      }
    }

    .dropdown-content {
      overflow-x: hidden;
      overflow-y: auto;
      max-width: 100%;

      @include media-breakpoint-up(md) {
        max-height: 22.2rem;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .dropdown-menu {
      border: 0;
      position: fixed !important;
      top: auto !important;
      right: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      margin: 0;
      width: 100%;
      max-width: 100% !important;
      height: auto;
      max-height: 75vh;
      transform: none !important;
      z-index: $zindex-modal;
      box-shadow: none;
      border-radius: 0;
      box-shadow: 0px 0rem 2rem 0px rgba(0, 0, 0, .4) !important;
      border-radius: 1rem 1rem 0 0 !important;
      transform: translateY(3rem) !important;

      @supports (-webkit-touch-callout: none) {
        // The hack for Internet Explorer, hmm, I mean Safari
        //height: 100vh;
        max-height: calc(var(--vh, 1vh) * 75);
        top: calc(var(--vh, 1vh) * 25) !important;

        .form-control {
          // Prevent IOS from zooming on tap
          font-size: 1rem;
        }
      }

      &.show {
        display: flex;
        flex-direction: column;
        transform: translateY(0) !important;
      }

      .dropdown-header {
        display: flex;
        gap: .8rem;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        font-size: $font-size-base;
        margin-bottom: 1rem;
        padding-top: 0;
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);

        button[name="back"] {
          margin-left: - $dropdown-item-padding-x * .5;
        }

        button[name="next"] {
          margin-right: - $dropdown-item-padding-x * .5;
        }

        button[type="button"] {
          border-radius: var(--bs-border-radius-pill);
          background-color: rgb(var(--bs-secondary-rgb));
          flex-grow: 1;

          &.active {
            background-color: rgb(var(--bs-black-rgb));
          }
        }
      }

      .dropdown-content {
        flex-grow: 1;
      }

      .dropdown-item {
        line-height: 2;
      }
    }
  }

  &:not(:last-child) {
    .rentals-form-input-inline {
      border-bottom: 1px solid $gray-400;
    }
  }

  .rentals-form-input-inline {
    padding: 2rem 0;
  }
}

rentals-form-option {
  &[type="submit"] {
    -webkit-appearance: none;
  }
}

option.placeholder {
  display: none;
}
