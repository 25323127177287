// Color system

// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: ();

$colors: (
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);

$primary:       #e74c3c;
$secondary:     $gray-500;
$light:         $gray-100;
$dark:          $gray-900;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "light":      $light,
  "dark":       $dark
);

// The contrast ratio to reach against white,
// to determine if color changes from "light" to "dark".
// Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:    2;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:   $black;
$color-contrast-light:  $white;

$btn-hover-bg-shade-amount:       20%;
$btn-hover-bg-tint-amount:        20%;
$btn-hover-border-shade-amount:   20%;
$btn-hover-border-tint-amount:    20%;
$btn-active-bg-shade-amount:      20%;
$btn-active-bg-tint-amount:       20%;
$btn-active-border-shade-amount:  20%;
$btn-active-border-tint-amount:   20%;

$text-shadow:   0 .1rem .3rem rgba($black, .25);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-sm:                .75rem;
$font-size-base:              .875rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.20;

$headings-font-family:        var(--bs-headings-font-family, inherit);

$dropdown-font-size:          $font-size-base;

$link-decoration:             none;

// Transitions

$transition-base:             all .2s ease-in-out !default;
$transition-cubic:            all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
$transition-cubic-short:      all 0.25s cubic-bezier(0.65, 0, 0.076, 1); 

// Zoom

$zoom-transition:               $transition-base;
$zoom-scale:                    1.1;

// Lift

$lift-transition:               $transition-base;
$lift-transform:                translate3d(0, -3px, 0);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           2rem;

// Sizes

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1920px
);

$container-max-widths: (
  sm: 640px,
  md: 720px,
  lg: 1060px,
  xl: 1140px,
  xxl: 1320px
);

$border-radius:               .25rem;
$border-radius-sm:            .2rem;
$border-radius-lg:            .5rem;
$border-radius-pill:          50rem;

$box-shadow:                  0 .5rem 1rem rgba($black, .15);
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075);

$spacer: 1rem;

$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: ($spacer * 4.5),
);

$input-btn-focus-width:       0;

$card-border-width:           0;
$card-border-radius:          .5rem;
$card-highlight-transform:    none;

$pagination-border-width:     0;
$pagination-disabled-bg:      transparent;

$modal-content-border-width:  0;
$modal-header-border-width:   1px;
$modal-footer-border-width:   1px;

$offcanvas-horizontal-width:  300px;

// Navbar

$navbar-nav-link-padding-x:         1rem;

$navbar-toggler-padding-y:          .25rem;
$navbar-toggler-padding-x:          .75rem;
$navbar-toggler-font-size:          $font-size-lg;
$navbar-toggler-border-radius:      $border-radius;
$navbar-toggler-focus-width:        0;

$navbar-dark-color:                 rgba($white, 1);
$navbar-dark-hover-color:           $primary;
$navbar-dark-active-color:          $primary;
$navbar-dark-disabled-color:        rgba($white, 1);
$navbar-dark-toggler-border-color:  rgba($white, 0);

$navbar-light-color:                rgba($black, 1);
$navbar-light-hover-color:          $primary;
$navbar-light-active-color:         $primary;
$navbar-light-disabled-color:       rgba($black, 1);
$navbar-light-toggler-border-color: rgba($black, 0);

$navbar-light-brand-color:          $navbar-light-color;
$navbar-light-brand-hover-color:    $navbar-light-color;
$navbar-dark-brand-color:           $navbar-dark-color;
$navbar-dark-brand-hover-color:     $navbar-dark-color;

// Toggle Fullscreen

$toggle-fullscreen-collapsed-bg:  $light;
$toggle-fullscreen-close-height:  4rem;

// Cookies Widget

$cookies-widget-color:                  $white;
$cookies-widget-accent-color:           $primary;
$cookies-widget-bg-color:               $dark;

// Calendar

$calendar-drop-month-min-width:             14rem;
$calendar-drop-font-size:                   .9rem;

$calendar-drop-pagination-hover-color:      $primary;

$calendar-cell-color:                       #444;

$calendar-disabled-bg-color:                #f0f0f0;
$calendar-disabled-color:                   #bfbfbf;

$calendar-highlighted-bg-color:             $primary;
$calendar-highlighted-color:                color-contrast($primary);

$calendar-invalid-bg-color:                 darken($calendar-disabled-bg-color,  5%);
$calendar-invalid-color:                    darken($calendar-disabled-color, 5%);

$calendar-cell-bg-opacity:                  .8;
$calendar-cell-border-radius:               6px;

// Booknow

$booknow-accent-primary:                    $primary;
$booknow-accent-primary-hover:              darken($primary, 5%);
$booknow-accent-secondary:                  $secondary;

// Leaflet

$leaflet-marker-icon-shadow:                0 1px 2px 0 rgba($black, .5);
$leaflet-marker-icon-bg:                    $primary;
$leaflet-marker-icon-active-bg:             $secondary;
