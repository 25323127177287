// Global

html {
  // Fix webkit bug where elements would lose antialiasing when animated
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  max-width: 100vw;
  overflow-x: hidden;

  &.modal-open, &.overflow-hidden, &[style="overflow: hidden; padding-right: 0px;"] {
    ._hj_feedback_container {
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }
}

// Hotjar

._hj_feedback_container {
  opacity: 1!important;
  visibility: visible !important;
  transition: opacity 0.3s ease-in-out !important;
}

// Utilities

.min-h-0 {
  min-height: 0;
}

.min-h-100 {
  min-height: 100%;
}

.max-h-100 {
  max-height: 100%;
}

.min-w-100 {
  min-width: 100%;
}

.min-vh-25 {
  min-height: 25vh;
}

.min-vh-50 {
  min-height: 50vh;
}

.max-vh-50 {
  max-height: 50vh;
}

.max-vh-100 {
  max-height: 100vh;
}

.min-ch-10 {
  min-width: 10ch !important;
}

.abbr {
  cursor: help;
  text-decoration: underline dotted;
}

.reverse-even:nth-child(2n) .flex-row-reverse-even {
  @include media-breakpoint-up(sm) {
    flex-direction: row-reverse;
  }
}

.row-cols-scroll-x-mobile {
  @include media-breakpoint-down(md) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 1rem;

    // &[data-scroll-pos-x="0"] {
    //   mask-image: linear-gradient(to right, $black 80%, rgba($black, 0) 100%);
    // }

    // &[data-scroll-pos-x="100"] {
    //   mask-image: linear-gradient(to left, $black 80%, rgba($black, 0) 100%);
    // }

    > * {
      flex: 0 0 auto;
      width: 65%;
    }
  }
}

// TODO remove
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.zindex-sticky {
  z-index: $zindex-sticky !important;
}

.zindex-popover {
  z-index: $zindex-popover !important;
}

.zindex-1 {
  z-index: 1 !important;
}

.zindex-100 {
  z-index: 100 !important;
}

.scroll-mt-navbar-height {
  scroll-margin-top: var(--navbar-height, '60px');
}

// Images

.img-object-fit-cover {
  object-fit: cover;
}

.img-object-fit-contain {
  object-fit: contain;
}

// Effects

@keyframes fadeIn {
  from {
   opacity: 0;
   transform: translate3d(0, 2rem, 0);
  }
  to {
   opacity: 1;
   transform: none;
  }
}

$i: 0;
$offset: 0.1;
$max: 6;

@for $i from 1 through $max {
  $delay: $i * $offset;

  .fade-in-delay-#{$i} {
    opacity: 0;
    animation:fadeIn 1.5s;
    animation-delay: #{$delay}s;
    animation-fill-mode: forwards;
  }
}

[class^="img-ratio-"],
[class*="img-ratio-"] {
  max-inline-size: 100%;
  block-size: auto;
}

.img-ratio-21x9 {
  aspect-ratio: 21/9;
}

.img-ratio-16x9 {
  aspect-ratio: 16/9;
}

.img-ratio-9x21 {
  aspect-ratio: 9/21;
}

.img-ratio-9x16 {
  aspect-ratio: 9/16;
}

.img-ratio-4x3 {
  aspect-ratio: 3/2;
}

.img-ratio-3x2 {
  aspect-ratio: 3/2;
}

.img-ratio-1x1 {
  aspect-ratio: 1/1;
}

.touch-pinch-zoom {
  touch-action: pinch-zoom !important;
}

.intersection-animation {
  .intersection-animation-item {
    --smily-intersection-animation-transform: 0, 5rem, 0;
    will-change: transform, opacity;
    transition: all 0.4s ease-in-out;
    transform: translate3d(var(--smily-intersection-animation-transform));
    opacity: 0;

    @include media-breakpoint-down(md) {
      transform: none;
    }
  }

  &.intersection-animated .intersection-animation-item {
    will-change: unset;
    opacity: 1;
    transform: none;
  }
}

.intersection-animation-left > .intersection-animation-item {
  --smily-intersection-animation-transform: 5rem, 0, 0;
}

.intersection-animation-right > .intersection-animation-item {
  --smily-intersection-animation-transform: -5rem, 0, 0;
}

.fade-in {
  transition: $zoom-transition;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.scale {
  transition: $transition-base;

  &.highlight,
  &:hover {
    transform: scale(1.38);
  }
}

.zoom {
  position: relative;
  overflow: hidden;

  img {
    transition: $zoom-transition;
  }

  &:hover img {
    transform: scale($zoom-scale);
  }
}

.zoom-reverse {
  position: relative;
  overflow: hidden;

  img {
    transition: $zoom-transition;
    transform: scale($zoom-scale);
  }

  &:hover img {
    transform: scale(1);
  }
}

.zoom-slow {
  img {
    transition: all 2s ease-in-out;
  }
}

.lift {
  transition: $lift-transition;

  &.highlight,
  &:focus,
  &:hover {
    transform: $lift-transform;
  }
}

.transition-base {
  transition: $transition-base;
}

.transition-translate {
  transition: all 0.4s ease-in-out;
}

.mask-image {
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, $black), to(rgba($black, 0)));
  -webkit-mask-image: linear-gradient($black 20%, rgba($black, 0) 100%);
  mask-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, $black), to(rgba($black, 0)));
  mask-image: linear-gradient($black 20%, rgba($black, 0) 100%);
}

.text-shadow {
  text-shadow: $text-shadow;
}

// SVGs

.fill-current-color {
  fill: currentColor !important;
}

.icon-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5em;

  svg {
    height: 1em;
  }
}

// Cards

.card {
  box-shadow: $card-box-shadow;
  transition: $transition-cubic-short;
}

@include media-breakpoint-up(lg) {
  .col > article.card {
    will-change: transform;

    &.highlight,
    &:hover {
      transform: $card-highlight-transform;
    }
  }
}

// Dropdowns

.dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: $transition-cubic;
  transform: translateY(1rem);
  box-shadow: $dropdown-box-shadow;
  top: 100%;

  &.show {
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  &.dropdown-menu-start {
    left: 0;
  }

  &.dropdown-menu-end {
    right: 0;
  }

  &[data-bs-popper] {
    top: calc(100% + 0.3rem);
  }
}

.dropdown-menu-col {
  min-width: 8rem;
}

.dropdown-toggle {
  &.show {
    &::after {
      transform: rotate(315deg) translate3d(-3px, 1px, 0);
    }
  }
}

.dropdown-toggle::after {
  transition: transform .2s cubic-bezier(0.65, 0, 0.076, 1);
  transform: rotate(135deg);
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-width: 2px 2px 0 0;
}

// Offcanvas

.offcanvas {
  .nav-link {
    padding-left: 0;
    padding-right: 0;
  }

  .nav-item.dropdown {
    flex-wrap: wrap;
  }

  .dropdown-menu {
    position: static !important;
    transform: none !important;
    display: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    border: 0;

    &.show {
      display: block;
      width: 100%;
    }
  }
}

.offcanvas-header .btn-close {
  margin-left: $offcanvas-padding-x * -.5;
}

// Loader

.loader {
  visibility: hidden;
  opacity: 0;
  transition: all .2s ease-in;
  pointer-events: unset;
  z-index: $zindex-sticky;

  &.fixed-top {
    z-index: $zindex-modal + 100;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

// Swiper

:root {
  --swiper-theme-color: var(--bs-primary);
  --swiper-pagination-color: var(--bs-white);
  --swiper-pagination-bullet-inactive-color: var(--bs-white);
  --swiper-pagination-bullet-inactive-opacity: .6;
  --swiper-navigation-color: var(--bs-white);
}

.card {
  --swiper-navigation-size: 1.6rem;

  .swiper-button-prev-custom, .swiper-button-next-custom {
    color: white;
    transition: $transition-base;
    transform: translateY(-50%);
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50%;
    z-index: 1;
  }

  .swiper-button-next-custom {
    right: 0;
  }

  &:hover {
    .swiper-button-prev-custom, .swiper-button-next-custom {
      opacity: 1;
      visibility: visible;
    }
  }
}

// add space fox box-shadow
swiper-container.row {
  padding-bottom: var(--bs-gutter-y);
  margin-bottom: calc(-1 * var(--bs-gutter-y));
}

// Alerts

.alert-danger {
  --#{$prefix}alert-color: #{shift-color($red, $alert-color-scale)};
  --#{$prefix}alert-bg: #{shift-color($red, $alert-bg-scale)};
  --#{$prefix}alert-border-color: #{shift-color($red, $alert-border-scale)};
  --#{$prefix}alert-link-color: #{shift-color($red, $alert-color-scale)};
}

.alert-success {
  --#{$prefix}alert-color: #{shift-color($green, $alert-color-scale)};
  --#{$prefix}alert-bg: #{shift-color($green, $alert-bg-scale)};
  --#{$prefix}alert-border-color: #{shift-color($green, $alert-border-scale)};
  --#{$prefix}alert-link-color: #{shift-color($green, $alert-color-scale)};
}

// Custom buttons

.btn.active {
  &.active-primary {
    // color: #{color-contrast($primary)};
    background-color: var(--#{$variable-prefix}primary);
    border-color: var(--#{$variable-prefix}primary);
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

input[type="search"] + .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 0.7rem;
  font-size: 0.575rem;
}

input[type="search"]:not(:placeholder-shown) {
  padding-right: 2rem;
}
