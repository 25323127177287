.rental-card {
  transition: $transition-base;
  overflow: hidden;

  & > a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}
