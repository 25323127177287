html {
  .dropdown-content {
    .BookingSyncCalendarWidget__calendar {
      font-size: $calendar-drop-font-size;

      .BookingSyncCalendarWidget__mCell {
        min-width: $calendar-drop-month-min-width;
      }

      .BookingSyncCalendarWidget__monthsWrapper {
        gap: $spacer;

        @include media-breakpoint-up(md) {
          flex-wrap: nowrap;
        }
      }

      caption {
        @include media-breakpoint-down(md) {
          background: white;
          position: sticky;
          top: 0;
          z-index: 100;
        }
      }

      .BookingSyncCalendarWidget__caption {
        margin-bottom: $spacer;
        line-height: 1.4rem;
      }

      // months pagination

      .BookingSyncCalendarWidget__forward,
      .BookingSyncCalendarWidget__back {
        height: 1.4rem;

        @include media-breakpoint-down(md) {
          z-index: 200;
          position: fixed;
          bottom: calc(75vh - 90px);
          top: unset;
          margin: 0 15px;
        }

        &:hover {
          fill: $calendar-drop-pagination-hover-color;
        }
      }
    }
  }

  .BookingSyncCalendarWidget__calendar {
    --bookingsync-calendar-highlighted-bg-color: #{$calendar-highlighted-bg-color} !important;
    --bookingsync-calendar-invalid-bg-color: #{$calendar-invalid-bg-color} !important;
    --bookingsync-calendar-disabled-bg-color: #{$calendar-disabled-bg-color} !important;

    --bookingsync-calendar-highlighted-color: #{$calendar-highlighted-color} !important;
    --bookingsync-calendar-invalid-color: #{$calendar-invalid-color} !important;
    --bookingsync-calendar-disabled-color: #{$calendar-disabled-color} !important;

    --bookingsync-calendar-cell-bg-opacity: #{$calendar-cell-bg-opacity} !important;
    --bookingsync-calendar-cell-border-radius: #{$calendar-cell-border-radius} !important;

    .BookingSyncCalendarWidget__cell {
      &::before,
      &::after {
        // fix blurry skew
        height: calc(100% + 2px);
        top: -1px;
      }
    }
  }
}
