@keyframes alertIn {
  0% {
    transform: scale(103%);
  }
  100% {
    transform: scale(100%);
  }
}

rental-booknow {
  [role="alert"] ul li {
    animation: alertIn 0.3s ease-in-out;
  }

  rentals-form-input {
    display: block;
  }

  .dropdown-toggle {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-menu {
    right: 0 !important;
    left: unset !important;
    min-width: 100% !important;
  }

  @include media-breakpoint-up(lg) {
    .dropdown-header,
    .dropdown-divider,
    .dropdown-footer {
      display: none !important;
    }
  }

  .input-group-number {
    &:hover,
    &:focus {
      .input-number-absolute {
        left: 0;
        opacity: 1;
        visibility: visible;
      }
    }

    [data-rental-booknow="fee-time-booked"] {
      text-align: center;
      width: calc(0.9rem + 2ch);
      -moz-appearance:textfield;
      padding: 0.3em 0;
      font-size: 0.8rem;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
      }
    }
  }

  .input-number-absolute {
    height: 100%;
    display: flex;
    gap: 0.3rem;
    padding-left: 0.3rem;
    background: inherit;
    position: absolute;
    left: -3rem;
    opacity: 0;
    visibility: hidden;
    transition: $transition-cubic-short;
  }
}
