rentals-map {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 50vh;

  @include media-breakpoint-up(lg) {
    position: sticky !important; // overrides leaflet
    top: calc(#{$input-height} + #{($spacer * 2)});
    height: calc(100vh - #{$input-height} - #{($spacer * 2)});
    z-index: $zindex-sticky - 1;
  }
}

.leaflet-touch {
  .leaflet-control-layers,
  .leaflet-bar {
    border: 0;
    box-shadow: $box-shadow-sm;
    margin: $grid-gutter-width * .5;
  }
}

.leaflet-bottom.leaflet-right {
  display: none !important;
}

.leaflet-top.leaflet-right {
  @include media-breakpoint-down(lg) {
    top: unset;
    bottom: 0;
  }
}

.rentals-map-focus-btn {
  position: absolute;
  display: inline-block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: $zindex-sticky;
  pointer-events: none;

  &, * {
    transition: $transition-cubic;
  }

  .icon {
    position: sticky;
    top: 50%;
    background-color: tint-color($light, 80%);
    transform: translate(-50%, -50%);
    margin: #{($spacer * 3)} 0;
    pointer-events: all;

    &:hover {
      background-color: $light;
    }
  }

  .label {
    display: none;
    padding-right: 1em;
    margin-left: -0.8em;
  }

  svg {
    margin: 0.7rem;
    fill: currentColor;
    transform: rotate(0deg);
  }

  &.active {
    .icon {
      transform: translate(1rem, -50%);
    }

    svg {
      transform: rotate(180deg);
    }

    .label {
      display: block;
    }
  }
}

#rentalsSearchByMap {
  @include media-breakpoint-down(lg) {
    top: unset;
    bottom: 0;
  }
}
