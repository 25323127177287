// Toggle on Scroll

toggle-on-scroll {
  opacity: 0;
  visibility: hidden;
  transition: $transition-base;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .btn {
    pointer-events: all;
  }
}

.toggle-on-scroll-translate {
  transform: translateY(1rem);

  &.show {
    transform: translateY(0);
  }
}
